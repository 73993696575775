.animation-delay-1{
    animation-delay: 800ms;
}

.animation-delay-2{
    animation-delay: 900ms;
}

.animation-delay-3{
    animation-delay: 1000ms;
}
